/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "In een artikel op Skipr wordt nader toegelicht dat de antistollingszorg in Nederland steeds veiliger wordt, maar dat er nog voldoende verbeterpunten zijn. Dat concludeert Marco Moesker (Nivel) in zijn proefschrift waarop hij onlangs promoveerde aan de Vrije Universiteit van Amsterdam."), "\n", React.createElement(_components.p, null, "Het complete artikel kunt u via ", React.createElement(_components.a, {
    href: "https://www.skipr.nl/nieuws/antistollingszorg-in-nederland-steeds-veiliger/"
  }, "deze link"), " lezen."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
